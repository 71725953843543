<template>
  <div>
    <a-row>
      <div class="title custom-flex">
        <div class="icon" />
        <span>任务统计</span>
      </div>
    </a-row>
    <a-row>
      <div class="going-task custom-flex custom-items-center">
        <div>
          <a-icon type="reconciliation" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>进行中任务数量</div>
          <div class="count">{{ totalTasksNumberInProgress }}</div>
        </div>
      </div>
    </a-row>
    <a-row>
      <div class="task-total-count custom-flex custom-items-center">
        <div>
          <a-icon type="snippets" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>任务总数</div>
          <div class="count">{{ totalTasksNumber }}</div>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import { findTenantTaskStatistics } from '@/api/home'
export default {
  name: 'TaskStatistics',
  data() {
    return {
      totalTasksNumber: 0,
      totalTasksNumberInProgress: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findTenantTaskStatistics().then(res => {
        if (res.code === 0) {
          this.totalTasksNumberInProgress = res.data.total_number_of_tasks_in_progress
          this.totalTasksNumber = res.data.total_number_of_tasks
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}
.going-task {
  border-radius: 10px;
  background: linear-gradient(to top right,#26a9f9, #26c8d5);
  margin: 22px 0 20px 0;
  padding: 20px 0 20px 50px;
}

.content {
  font-size: 20px;
  color: #ffffff;
  margin-left: 20px;

  .count {
    font-size: 40px;
  }
}

.task-total-count {
  border-radius: 10px;
  padding: 20px 0 20px 50px;
  background: linear-gradient(to top right, #ffa111, #ff9b2e);
}

</style>

